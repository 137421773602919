import React, { useState } from "react"

import probStyles from './probability_box.module.scss'

import FetchProbs from "./fetch_probabilities"
import ProbChart from "./prob_chart"

const ProbBox = ({game_id, homeTeamColor, awayTeamColor}) => {

    const tabs = [
        {
            'index' : 0,
            'id' : 'winProb',
            'name' : 'Win %',
            'measure' : 'wp',
        }, 
        {
            'index' : 1,
            'id' : 'coverProb',
            'name' : 'Cover %',
            'measure' : 'cp',
        }, 
    ]

    const [selectedTab, setSelectedTab] = useState(0);

    const tabToDisplay =  tabs[selectedTab]['measure']

    const probData = FetchProbs(
        'https://b8kngguc7d.execute-api.us-east-2.amazonaws.com/prod/game-pbp',
        game_id
    )


    return(

        <div className={probStyles.main_container}>
            <div className={probStyles.tab_container}>
                {tabs.map((item) => {
                    return (
                        <button
                            className={probStyles.tab_button}
                            key={item.id}
                            onClick={()=> setSelectedTab(item.index)}
                            data-active={selectedTab===item.index ? true : false }
                        >
                            {item.name}
                        </button>
                    )
                })}
            </div>
            {(probData[0] === 'Loading') ? (
                    <p className={probStyles.loadingP}>Loading...</p>
            ) : (
                <ProbChart
                    data={probData}
                    chartType={tabToDisplay}
                />
            )}

        </div>

    )

}

export default ProbBox