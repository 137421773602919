
import React from 'react'
import { Link } from "gatsby"

import idStyles from './game_id_nav.module.scss'

const IdNavBox = ({ data, is_home }) => {

    var prev_id = ''
    var next_id = ''
    var prev_link = ''
    var next_link = ''

    
    if (is_home) {
        prev_id = data.gameSummariesCsv.prev_game_id_home_slug
        next_id = data.gameSummariesCsv.next_game_id_home_slug
    } else {
        prev_id = data.gameSummariesCsv.prev_game_id_away_slug
        next_id = data.gameSummariesCsv.next_game_id_away_slug
    }

    if (prev_id === '') {
        prev_link = '/games'
    } else {
        prev_link = '/games/' + prev_id + '/'
    }

    if (next_id === '') {
        next_link = '/games'
    } else {
        next_link = '/games/' + next_id + '/'
    }

    return (
        <div className={idStyles.team_nav_box}>
            <Link className={idStyles.team_id_link} to={prev_link}>Prev</Link>
            <Link className={idStyles.team_id_link} to={next_link}>Next</Link>
        </div>
    )

}

export default IdNavBox