import React from 'react'

const DisplayScore = ({ data, is_home }) => {

    if (is_home) {
        if (parseInt(data.gameSummariesCsv.home_win) === 1) {
            return (
                <p style={{
                    color: 'Black',
                    fontWeight: 'bold',
                    margin: '0px',
                }}>
                    &#9656; {parseInt(data.gameSummariesCsv.home_score)}
                </p>
            )
        } else {
            return (
                <p style={{
                    color: 'Gray',
                    fontWeight: 'bold',
                    margin: '0px',
                }}>
                    {parseInt(data.gameSummariesCsv.home_score)}
                </p>
            )
        }
    } else {
        if (parseInt(data.gameSummariesCsv.away_win) === 1) {
            return (
                <p style={{
                    color: 'Black',
                    fontWeight: 'bold',
                    margin: '0px',
                }}>
                    {parseInt(data.gameSummariesCsv.away_score)} &#9666;
                </p>
            )
        } else {
            return (
                <p style={{
                    color: 'Gray',
                    fontWeight: 'bold',
                    margin: '0px',
                }}>
                    {parseInt(data.gameSummariesCsv.away_score)}
                </p>
            )
        }
    }

}

export default DisplayScore