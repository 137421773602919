
const DisplayEpa = ({ data, is_home, pos }) => {

    if (is_home) {
        if (pos === 'qb') {
            return 'Total Epa: ' + parseFloat(data.gameSummariesCsv.pass_leader_epa_home).toFixed(2)
        } if (pos === 'rec') {
            return 'Total Epa: ' + parseFloat(data.gameSummariesCsv.rec_leader_epa_home).toFixed(2)
        } else {
            return 'Total Epa: ' + parseFloat(data.gameSummariesCsv.rush_leader_epa_home).toFixed(2)
        }
    } else {
        if (pos === 'qb') {
            return 'Total Epa: ' + parseFloat(data.gameSummariesCsv.pass_leader_epa_away).toFixed(2)
        } if (pos === 'rec') {
            return 'Total Epa: ' + parseFloat(data.gameSummariesCsv.rec_leader_epa_away).toFixed(2)
        } else {
            return 'Total Epa: ' + parseFloat(data.gameSummariesCsv.rush_leader_epa_away).toFixed(2)
        }
    }

}

export default DisplayEpa