
import React from 'react'
import { VictoryArea, VictoryChart, VictoryAxis, VictoryLine, VictoryLabel, VictoryTooltip, VictoryVoronoiContainer } from 'victory'



const ProbChart = ( { data, chartType } ) => {

    if (data.length === 0) {
        // make sure no data doesnt take down page //

        return (
            <div>
                Loading...
            </div>
        )
        
    }

    const homeTeamColor = data[0]['home_team_color']
    const awayTeamColor = data[0]['away_team_color']

    const homeTeamName = data[0]['home_team_name']
    const awayTeamName = data[0]['away_team_name']

    var chartTitle = ''
    var chartSubTitle = ''

    var gradientMidPoint = ''

    if (chartType === 'wp') {
        gradientMidPoint = data[0]['wp_gradient_midpoint'].replace("\"")
        chartTitle = `${awayTeamName} @ ${homeTeamName} Win Probability`
        chartSubTitle = '@benbaldwin win probability model via nflfastR'
    } else {
        gradientMidPoint = data[0]['cp_gradient_midpoint']
        chartTitle = `${awayTeamName} @ ${homeTeamName} Cover Probability`
        chartSubTitle = '@greerreNFL cover probability model built with nflfastR pbp'
    }

    const chartData = data.map(row => {

        var prob = 0

        if (chartType === 'wp') {
            prob = parseFloat(row['vegas_home_wp'])
        } else {
            prob = parseFloat(row['home_cover_prob'])  
        }

        if (prob > .5) {
            return (
                {
                    x : parseInt(row.play_number),
                    y : prob,
                    y0 : .50,
                    line_color : row.home_team_color,
                    line_y : prob,
                    vinoriLabel : `${row.home_team}: ${Math.round(100*prob)}%`
                }
            )
        } else {
            return(
                {
                    x : parseInt(row.play_number),
                    y : .50,
                    y0 : prob,
                    line_color : row.away_team_color,
                    line_y : prob,
                    vinoriLabel : `${row.away_team}: ${Math.round(100*(1-prob))}%`
                }
            )
        }

    })


    return (
        <div>
            <svg style={{ height: 0 }}>
                <defs>
                    <linearGradient
                        id="gradientStroke"
                        x1="0%"
                        x2="0%"
                        y1="0%"
                        y2="100%"
                    >
                        <stop offset="0%" stopColor={homeTeamColor} stopOpacity=".5"/>
                        <stop offset={gradientMidPoint} stopColor="white" stopOpacity="0"/>
                        <stop offset="100%" stopColor={awayTeamColor} stopOpacity=".5"/>
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                width={550}
                height={300}
                containerComponent={
                    <VictoryVoronoiContainer
                        voronoiBlacklist={["areaChart"]}
                        labels={({ datum }) => datum.vinoriLabel}
                        labelComponent={
                            <VictoryTooltip
                                pointerLength={10}
                                flyoutStyle={{
                                    fillOpacity : 1,
                                    fill : "white",
                                    strokeOpacity : 0.2
                                }}
                                style={{
                                    fontSize : 10,
                                    fontWeight : 400
                                }}
                            />
                        }
                    />
                }
                >
                <VictoryAxis
                    label='Play Number'
                    offsetY={49}
                    style={{
                        axisLabel: {
                            fontSize: '12px',
                            padding: '30',
                            fontWeight: 'bold'
                        },
                        tickLabels: {
                            fontSize: '8px',
                            fill: '#808080',
                        }
                    }}
                />
                <VictoryAxis
                    dependentAxis
                    tickValues={[0, .5, 1]}
                    tickFormat={(t) => `${t>.5 ? 100 : t===.5 ? 50 : 100}%`}
                    domain={[0, 1]}
                    style={{
                        tickLabels: {
                            fontSize: '8px',
                            fill: '#808080',
                        }
                }}
                />
                <VictoryLine
                    style={{
                        data: {
                            stroke: "black",
                            strokeWidth: 1,
                            strokeDasharray: 3,
                            strokeOpacity: .35
                        }
                    }}
                    data={chartData}
                    x='x'
                    y={() => .5}
                />
                <VictoryArea
                    name = "areaChart"
                    style={{
                        data: {
                            fill : "url(#gradientStroke)"
                        },
                        labels: {
                            fontSize : 12
                        }
                    }}
                    data={chartData}
                />
                <VictoryLine
                    style={{
                        data: {
                            stroke: "black",
                            strokeWidth: 1,
                        }
                    }}
                    data={chartData}
                    x='x'
                    y='line_y'
                />
                <VictoryLabel
                    textAnchor='start'
                    verticalAnchor = 'middle'
                    style={{
                        fontSize: 15,
                        fill: homeTeamColor,
                        fillOpacity: .25,
                        fontFamily: 'Poppins'
                    }}
                    text={homeTeamName}
                    x={55}
                    y={55}
                />
                <VictoryLabel
                    textAnchor='start'
                    verticalAnchor = 'middle'
                    style={{
                        fontSize: 15,
                        fill: awayTeamColor,
                        fillOpacity: .25,
                        fontFamily: 'Poppins'
                    }}
                    text={awayTeamName}
                    x={55}
                    y={240}
                />
                <VictoryLabel
                    textAnchor='start'
                    verticalAnchor = 'middle'
                    style={{
                        fontSize: 13,
                        fill: "black",
                        fillOpacity: 1,
                        fontWeight: "bold",
                        fontFamily: 'Poppins'
                    }}
                    text={chartTitle}
                    x={20}
                    y={5}
                />
                <VictoryLabel
                    textAnchor='start'
                    verticalAnchor = 'middle'
                    style={{
                        fontSize: 10,
                        fill: "black",
                        fillOpacity: .25,
                        fontFamily: 'Poppins'
                    }}
                    text={chartSubTitle}
                    x={20}
                    y={22}
                />
            </VictoryChart>
        </div>
    )

}

export default ProbChart