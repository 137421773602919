
const PassLine = ({ data, is_home }) => {

    var comp = 0
    var att = 0
    var yds = 0
    var tds = 0
    var ints = 0

    if (is_home) {
        comp = parseInt(data.gameSummariesCsv.pass_leader_comp_home)
        att = parseInt(data.gameSummariesCsv.pass_leader_att_home)
        yds = parseInt(data.gameSummariesCsv.pass_leader_yards_home)
        tds = parseInt(data.gameSummariesCsv.pass_leader_tds_home)
        ints = parseInt(data.gameSummariesCsv.pass_leader_ints_home)
    } else {
        comp = parseInt(data.gameSummariesCsv.pass_leader_comp_away)
        att = parseInt(data.gameSummariesCsv.pass_leader_att_away)
        yds = parseInt(data.gameSummariesCsv.pass_leader_yards_away)
        tds = parseInt(data.gameSummariesCsv.pass_leader_tds_away)
        ints = parseInt(data.gameSummariesCsv.pass_leader_ints_away)     
    }

    if (tds === 0) {
        tds = ''
    } else {
        tds = tds + ' TD, '
    }

    if (ints === 0) {
        ints = ''
    } else {
        ints = ints + ' INT'
    }

    return comp + '-' + att + ', ' + yds + ', ' + tds + ints

}

export default PassLine