import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import Head from '../../components/head'

import SummaryBox from './summary_components/result_box'
import TeamBox from './summary_components/summary_box'
import mainStyles from './summaries.module.scss'
import ProbBox from './summary_components/probability_box'



export const query = graphql`
    query (
       $game_id: String! 
    ) {
        gameSummariesCsv (
            game_id : {
                eq : $game_id
            }
        ) {
            game_id
            game_id_slug
            season
            week
            home_team
            away_team
            pass_epa_home
            rush_epa_home
            pass_yards_home
            rush_yards_home
            passes_home
            rushes_home
            pass_epa_away
            rush_epa_away
            pass_yards_away
            rush_yards_away
            passes_away
            rushes_away
            penalty_epa_home
            penalty_epa_away
            penalty_yards_home
            penalty_yards_away
            penalties_home
            penalties_away
            ints_home
            fumbles_home
            ints_away
            fumbles_away
            late_down_epa_home
            redzone_epa_home
            lost_turnover_epa_home
            fg_epa_home
            late_down_epa_away
            redzone_epa_away
            lost_turnover_epa_away
            fg_epa_away
            plays_home
            total_yards_home
            total_epa_home
            y_p_p_home
            y_p_pass_home
            y_p_rush_home
            epa_p_p_home
            epa_p_pass_home
            epa_p_rush_home
            plays_away
            total_yards_away
            total_epa_away
            y_p_p_away
            y_p_pass_away
            y_p_rush_away
            epa_p_p_away
            epa_p_pass_away
            epa_p_rush_away
            turnover_epa_home
            turnover_epa_away
            pass_leader_home
            pass_leader_id_home
            pass_leader_att_home
            pass_leader_comp_home
            pass_leader_yards_home
            pass_leader_tds_home
            pass_leader_ints_home
            pass_leader_epa_home
            rec_leader_home
            rec_leader_id_home
            rec_leader_rec_home
            rec_leader_rec_yards_home
            rec_leader_tds_home
            rec_leader_epa_home
            rush_leader_home
            rush_leader_id_home
            rush_leader_rush_home
            rush_leader_rush_yards_home
            rush_leader_tds_home
            rush_leader_epa_home
            pass_leader_away
            pass_leader_id_away
            pass_leader_att_away
            pass_leader_comp_away
            pass_leader_yards_away
            pass_leader_tds_away
            pass_leader_ints_away
            pass_leader_epa_away
            rec_leader_away
            rec_leader_id_away
            rec_leader_rec_away
            rec_leader_rec_yards_away
            rec_leader_tds_away
            rec_leader_epa_away
            rush_leader_away
            rush_leader_id_away
            rush_leader_rush_away
            rush_leader_rush_yards_away
            rush_leader_tds_away
            rush_leader_epa_away
            gameday_display
            stadium
            away_score
            home_score
            spread_line
            total_line
            home_spread_result
            total_result
            home_win
            away_win
            home_team_name
            home_team_logo_espn
            away_team_name
            away_team_logo_espn
            pass_leader_headshot_home
            pass_leader_headshot_away
            rec_leader_headshot_home
            rec_leader_headshot_away
            rush_leader_headshot_home
            rush_leader_headshot_away
            prev_game_id_home_slug
            prev_game_id_away_slug
            next_game_id_home_slug
            next_game_id_away_slug
        }
    }
`


const Summary = (props) => {

    const markupData = [
        {
            "@context": "http://schema.org",
            "@type": "SportsEvent",
            "name" : `${props.data.gameSummariesCsv.away_team_name} @ ${props.data.gameSummariesCsv.home_team_name} Box Score, Week ${props.data.gameSummariesCsv.week}, ${props.data.gameSummariesCsv.season}`,
            "description" : `Box score with advanced analytics like EPA, Win Probability, and Cover Probability for ${props.data.gameSummariesCsv.away_team_name} @ ${props.data.gameSummariesCsv.home_team_name} -- ${props.data.gameSummariesCsv.gameday_display}`,
            "url" : `https://www.nfeloapp.com/games/${props.data.gameSummariesCsv.game_id_slug}/`,
            "startDate" : props.data.gameSummariesCsv.gameday_display,
            "endDate" : props.data.gameSummariesCsv.gameday_display,
            "image" : props.data.gameSummariesCsv.home_team_logo_espn,
            "sport" : "Football",
            "homeTeam" : {
                "@context": "http://schema.org",
                "name": props.data.gameSummariesCsv.home_team_name,
                "@type": "SportsTeam",
                "sameAs": `https://www.nfeloapp.com/teams/${props.data.gameSummariesCsv.home_team.toLowerCase()}/${props.data.gameSummariesCsv.season}/`,
                "logo" : props.data.gameSummariesCsv.home_team_logo_espn,
                "sport" : "Football",
                "athlete" : {
                    "@context": "http://schema.org",
                    "@type": "Person",
                    "givenName" : props.data.gameSummariesCsv.pass_leader_home
                }
            },
            "awayTeam" : {
                "@context": "http://schema.org",
                "name": props.data.gameSummariesCsv.away_team_name,
                "@type": "SportsTeam",
                "sameAs": `https://www.nfeloapp.com/teams/${props.data.gameSummariesCsv.away_team.toLowerCase()}/${props.data.gameSummariesCsv.season}/`,
                "logo" : props.data.gameSummariesCsv.away_team_logo_espn,
                "sport" : "Football",
                "athlete" : {
                    "@context": "http://schema.org",
                    "@type": "Person",
                    "givenName" : props.data.gameSummariesCsv.pass_leader_away
                }
            },
            "location" : {
                "@context": "http://schema.org",
                "@type": "Place",
                "name": props.data.gameSummariesCsv.stadium
            }
        }
    ]
    
    return (
        <Layout>
            <Head
                title={`${props.data.gameSummariesCsv.away_team_name} @ ${props.data.gameSummariesCsv.home_team_name} Box Score, Week ${props.data.gameSummariesCsv.week}, ${props.data.gameSummariesCsv.season}`}
                pageDescription={`Box score with advanced analytics like EPA, Win Probability, and Cover Probability for ${props.data.gameSummariesCsv.away_team_name} @ ${props.data.gameSummariesCsv.home_team_name} -- ${props.data.gameSummariesCsv.gameday_display}`}
                schemaMarkup={markupData}
            />
            <div className={mainStyles.summaryContainer}>
                <SummaryBox data={props.data}/>
                <ProbBox game_id={props.data.gameSummariesCsv.game_id}/>
                <TeamBox data={props.data}/>
            </div>
        </Layout>
    )
}

export default Summary
