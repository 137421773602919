import React from 'react'

import DisplayScore from '../../../helper_js/display_score'
import DisplayEpa from '../../../helper_js/display_player_epa'
import PassLine from '../../../helper_js/display_qb_line'
import RecLine from '../../../helper_js/display_rec_line'
import RushLine from '../../../helper_js/display_rush_line'
import IdNavBox from '../../../helper_js/game_id_nav'

import summaryBoxStyles from './result_box.module.scss'

const SummaryBox = (props) => {

    return (
        <div className={summaryBoxStyles.mainContainer}>
            <table className={summaryBoxStyles.main_table}>
                <tbody>
                    <tr className={summaryBoxStyles.box_main_row}>
                        <td className={summaryBoxStyles.away_team_name} style={{width : '26%',}}>
                            {props.data.gameSummariesCsv.away_team_name}
                        </td>
                        <td style={{width : '10%',}}>
                            <div style={{verticalAlign : 'center',}}>
                                <img
                                    className={summaryBoxStyles.team_logo}
                                    src={props.data.gameSummariesCsv.away_team_logo_espn}
                                    alt={props.data.gameSummariesCsv.away_team_name}>
                                </img>
                            </div>
                        </td>
                        <td className={summaryBoxStyles.team_score} style={{width : '10%', textAlign : 'left',}}>
                            <DisplayScore data={props.data} is_home={false}/>
                        </td>
                        <td className={summaryBoxStyles.final} style={{width : '8%',}}>
                            Final
                        </td>
                        <td className={summaryBoxStyles.team_score} style={{width : '10%', textAlign : 'right',}}>
                            <DisplayScore data={props.data} is_home={true}/>
                        </td>
                        <td style={{width : '10%',}}>
                            <div style={{verticalAlign : 'center',}}>
                                <img
                                    className={summaryBoxStyles.team_logo}
                                    src={props.data.gameSummariesCsv.home_team_logo_espn}
                                    alt={props.data.gameSummariesCsv.home_team_name}>
                                </img>
                            </div>
                        </td>
                        <td className={summaryBoxStyles.home_team_name} style={{width : '26%',}}>
                            {props.data.gameSummariesCsv.home_team_name}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={summaryBoxStyles.nav_box_div}>
                <IdNavBox data={props.data} is_home={false}/>
                <IdNavBox data={props.data} is_home={true}/>
            </div>
            <div className={summaryBoxStyles.meta_results}>
                <p className={summaryBoxStyles.meta_point} style={{textAlign: 'right',}}>
                    Home Spread: {props.data.gameSummariesCsv.spread_line} ({props.data.gameSummariesCsv.home_spread_result})
                </p>
                <p className={summaryBoxStyles.meta_point} style={{textAlign: 'left',}}>
                    Total: {props.data.gameSummariesCsv.total_line} ({props.data.gameSummariesCsv.total_result})
                </p>
            </div>
            <div className={summaryBoxStyles.divider}>
            </div>
            <div className={summaryBoxStyles.leader_container}>
                <p className={summaryBoxStyles.leader_title}>Passing</p>
                <div className={summaryBoxStyles.leader_box}>
                    <div className={summaryBoxStyles.player_icon_div}>
                        <div className={summaryBoxStyles.player_icon_container}>
                            <img
                                className={summaryBoxStyles.player_icon}
                                src={props.data.gameSummariesCsv.pass_leader_headshot_away}
                                alt={props.data.gameSummariesCsv.pass_leader_away}>
                            </img>
                        </div>
                    </div>
                    <div className={summaryBoxStyles.player_stat_div}>
                        <p className={summaryBoxStyles.player_name} style={{textAlign: 'right'}}>
                            {props.data.gameSummariesCsv.pass_leader_away}
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'right'}}>
                            <PassLine data={props.data} is_home={false} />
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'right'}}>
                            <DisplayEpa data={props.data} is_home={false} pos={'qb'}/>
                        </p>
                    </div>
                    <div className={summaryBoxStyles.center_divider_left}></div>
                    <div className={summaryBoxStyles.center_divider_right}></div>
                    <div className={summaryBoxStyles.player_stat_div}>
                        <p className={summaryBoxStyles.player_name} style={{textAlign: 'left'}}>
                            {props.data.gameSummariesCsv.pass_leader_home}
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'left'}}>
                            <PassLine data={props.data} is_home={true} />
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'left'}}>
                            <DisplayEpa data={props.data} is_home={true} pos={'qb'}/>
                        </p>
                    </div>
                    <div className={summaryBoxStyles.player_icon_div}>
                        <div className={summaryBoxStyles.player_icon_container}>
                            <img
                                className={summaryBoxStyles.player_icon}
                                src={props.data.gameSummariesCsv.pass_leader_headshot_home}
                                alt={props.data.gameSummariesCsv.pass_leader_home}>
                            </img>
                        </div>
                    </div>
                </div>
                <p className={summaryBoxStyles.leader_title}>Receiving</p>
                <div className={summaryBoxStyles.leader_box}>
                    <div className={summaryBoxStyles.player_icon_div}>
                        <div className={summaryBoxStyles.player_icon_container}>
                            <img
                                className={summaryBoxStyles.player_icon}
                                src={props.data.gameSummariesCsv.rec_leader_headshot_away}
                                alt={props.data.gameSummariesCsv.rec_leader_away}>
                            </img>
                        </div>
                    </div>
                    <div className={summaryBoxStyles.player_stat_div}>
                        <p className={summaryBoxStyles.player_name} style={{textAlign: 'right'}}>
                            {props.data.gameSummariesCsv.rec_leader_away}
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'right'}}>
                            <RecLine data={props.data} is_home={false} />
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'right'}}>
                            <DisplayEpa data={props.data} is_home={false} pos={'rec'}/>
                        </p>
                    </div>
                    <div className={summaryBoxStyles.center_divider_left}></div>
                    <div className={summaryBoxStyles.center_divider_right}></div>
                    <div className={summaryBoxStyles.player_stat_div}>
                        <p className={summaryBoxStyles.player_name} style={{textAlign: 'left'}}>
                            {props.data.gameSummariesCsv.rec_leader_home}
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'left'}}>
                            <RecLine data={props.data} is_home={true} />
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'left'}}>
                            <DisplayEpa data={props.data} is_home={true} pos={'rec'}/>
                        </p>
                    </div>
                    <div className={summaryBoxStyles.player_icon_div}>
                        <div className={summaryBoxStyles.player_icon_container}>
                            <img
                                className={summaryBoxStyles.player_icon}
                                src={props.data.gameSummariesCsv.rec_leader_headshot_home}
                                alt={props.data.gameSummariesCsv.rec_leader_home}>
                            </img>
                        </div>
                    </div>
                </div>
                <p className={summaryBoxStyles.leader_title}>Rushing</p>
                <div className={summaryBoxStyles.leader_box}>
                    <div className={summaryBoxStyles.player_icon_div}>
                        <div className={summaryBoxStyles.player_icon_container}>
                            <img
                                className={summaryBoxStyles.player_icon}
                                src={props.data.gameSummariesCsv.rush_leader_headshot_away}
                                alt={props.data.gameSummariesCsv.rush_leader_away}>
                            </img>
                        </div>
                    </div>
                    <div className={summaryBoxStyles.player_stat_div}>
                        <p className={summaryBoxStyles.player_name} style={{textAlign: 'right'}}>
                            {props.data.gameSummariesCsv.rush_leader_away}
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'right'}}>
                            <RushLine data={props.data} is_home={false} />
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'right'}}>
                            <DisplayEpa data={props.data} is_home={false} pos={'rush'}/>
                        </p>
                    </div>
                    <div className={summaryBoxStyles.center_divider_left}></div>
                    <div className={summaryBoxStyles.center_divider_right}></div>
                    <div className={summaryBoxStyles.player_stat_div}>
                        <p className={summaryBoxStyles.player_name} style={{textAlign: 'left'}}>
                            {props.data.gameSummariesCsv.rush_leader_home}
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'left'}}>
                            <RushLine data={props.data} is_home={true} />
                        </p>
                        <p className={summaryBoxStyles.player_stat} style={{textAlign: 'left'}}>
                            <DisplayEpa data={props.data} is_home={true} pos={'rush'}/>
                        </p>
                    </div>
                    <div className={summaryBoxStyles.player_icon_div}>
                        <div className={summaryBoxStyles.player_icon_container}>
                            <img
                                className={summaryBoxStyles.player_icon}
                                src={props.data.gameSummariesCsv.rush_leader_headshot_home}
                                alt={props.data.gameSummariesCsv.rush_leader_home}>
                            </img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SummaryBox