
const RecLine = ({ data, is_home }) => {

    var rec = 0
    var yds = 0
    var tds = 0

    if (is_home) {
        rec = parseInt(data.gameSummariesCsv.rec_leader_rec_home)
        yds = parseInt(data.gameSummariesCsv.rec_leader_rec_yards_home)
        tds = parseInt(data.gameSummariesCsv.rec_leader_tds_home)
    } else {
        rec = parseInt(data.gameSummariesCsv.rec_leader_rec_away)
        yds = parseInt(data.gameSummariesCsv.rec_leader_rec_yards_away)
        tds = parseInt(data.gameSummariesCsv.rec_leader_tds_away)    
    }

    if (tds === 0) {
        tds = ''
    } else {
        tds = tds + ' TD'
    }

    return rec + ' RECs, ' + yds + ' YDs, ' + tds

}

export default RecLine