import React from 'react'

import teamBoxStyles from './summary_box.module.scss'


const TeamBox = (props) => {

    return (
        <div className={teamBoxStyles.mainContainer}>
            <div className={teamBoxStyles.tableHolder}>
                <table className={teamBoxStyles.summaryTable}>
                    <tbody>
                        <tr className={teamBoxStyles.sectionHeader}>
                            <td style={{width: '60%'}}>
                                Volume
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{verticalAlign : 'center',}}>
                                    <img
                                        className={teamBoxStyles.team_logo}
                                        src={props.data.gameSummariesCsv.away_team_logo_espn}
                                        alt={props.data.gameSummariesCsv.away_team_name}>
                                    </img>
                                </div>
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{verticalAlign : 'center',}}>
                                    <img
                                        className={teamBoxStyles.team_logo}
                                        src={props.data.gameSummariesCsv.home_team_logo_espn}
                                        alt={props.data.gameSummariesCsv.home_team_name}>
                                    </img>
                                </div>
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Plays
                            </td>
                            <td>
                                {props.data.gameSummariesCsv.plays_away}
                            </td>
                            <td>
                                {props.data.gameSummariesCsv.plays_home}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Passes
                            </td>
                            <td>
                                {props.data.gameSummariesCsv.passes_away}
                            </td>
                            <td>
                                {props.data.gameSummariesCsv.passes_home}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Rushes
                            </td>
                            <td>
                                {props.data.gameSummariesCsv.rushes_away}
                            </td>
                            <td>
                                {props.data.gameSummariesCsv.rushes_home}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Yards
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.total_yards_away)}
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.total_yards_home)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Passing
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.pass_yards_away)}
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.pass_yards_home)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Rushing
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.rush_yards_away)}
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.rush_yards_home)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                EPA
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.total_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.total_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Passing
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.pass_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.pass_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Rushing
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.rush_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.rush_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Turnovers
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.ints_away) + parseInt(props.data.gameSummariesCsv.fumbles_away)}
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.ints_home) + parseInt(props.data.gameSummariesCsv.fumbles_home)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Ints
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.ints_away)}
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.ints_home)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Fumbles Lost
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.fumbles_away)}
                            </td>
                            <td>
                                {parseInt(props.data.gameSummariesCsv.fumbles_home)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                EPA Lost
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.lost_turnover_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.lost_turnover_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionHeader}>
                            <td style={{width: '60%'}}>
                                Efficiency
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{verticalAlign : 'center',}}>
                                    <img
                                        className={teamBoxStyles.team_logo}
                                        src={props.data.gameSummariesCsv.away_team_logo_espn}
                                        alt={props.data.gameSummariesCsv.away_team_name}>
                                    </img>
                                </div>
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{verticalAlign : 'center',}}>
                                    <img
                                        className={teamBoxStyles.team_logo}
                                        src={props.data.gameSummariesCsv.home_team_logo_espn}
                                        alt={props.data.gameSummariesCsv.home_team_name}>
                                    </img>
                                </div>
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Yards / Play
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.y_p_p_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.y_p_p_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Yards / Pass
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.y_p_pass_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.y_p_pass_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                Yards / Rush
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.y_p_rush_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.y_p_rush_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                EPA / Play
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.epa_p_p_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.epa_p_p_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                EPA / Pass
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.epa_p_pass_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.epa_p_pass_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowSub}>
                            <td>
                                EPA / Rush
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.epa_p_rush_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.epa_p_rush_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionHeader}>
                            <td style={{width: '60%'}}>
                                Leverage
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{verticalAlign : 'center',}}>
                                    <img
                                        className={teamBoxStyles.team_logo}
                                        src={props.data.gameSummariesCsv.away_team_logo_espn}
                                        alt={props.data.gameSummariesCsv.away_team_name}>
                                    </img>
                                </div>
                            </td>
                            <td style={{width: '20%'}}>
                                <div style={{verticalAlign : 'center',}}>
                                    <img
                                        className={teamBoxStyles.team_logo}
                                        src={props.data.gameSummariesCsv.home_team_logo_espn}
                                        alt={props.data.gameSummariesCsv.home_team_name}>
                                    </img>
                                </div>
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Late Down EPA
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.late_down_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.late_down_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Redzone EPA
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.redzone_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.redzone_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                Turnover EPA (net)
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.turnover_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.turnover_epa_home).toFixed(1)}
                            </td>
                        </tr>
                        <tr className={teamBoxStyles.sectionDataRowMain}>
                            <td>
                                FG EPA
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.fg_epa_away).toFixed(1)}
                            </td>
                            <td>
                                {parseFloat(props.data.gameSummariesCsv.fg_epa_home).toFixed(1)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default TeamBox