
const RushLine = ({ data, is_home }) => {

    var rush = 0
    var yds = 0
    var tds = 0

    if (is_home) {
        rush = parseInt(data.gameSummariesCsv.rush_leader_rush_home)
        yds = parseInt(data.gameSummariesCsv.rush_leader_rush_yards_home)
        tds = parseInt(data.gameSummariesCsv.rush_leader_tds_home)
    } else {
        rush = parseInt(data.gameSummariesCsv.rush_leader_rush_away)
        yds = parseInt(data.gameSummariesCsv.rush_leader_rush_yards_away)
        tds = parseInt(data.gameSummariesCsv.rush_leader_tds_away)    
    }

    if (tds === 0) {
        tds = ''
    } else {
        tds = tds + ' TD'
    }

    return rush + ' CARs, ' + yds + ' YDs, ' + tds

}

export default RushLine