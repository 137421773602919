import { useEffect, useState } from "react"

const FetchProbs = (urlRoot, gameId) => {

    const [probData, setProbData] = useState(['Loading']);

    useEffect(() => {

        var fetchUrl = urlRoot

        fetchUrl = fetchUrl + '?game_id=' + gameId

        fetchData(fetchUrl)

    }, [urlRoot, gameId])

    const fetchData = async (fetchUrl) => {
        await fetch(fetchUrl).then(
            response => response.json()
        ).then(data => {
            setProbData(data)
        })
    }

    return probData

}


export default FetchProbs